html,
body {
  height: 100%;
  margin: 0;
}

body {
  padding: 0;
  font-family: "Helvetica Neue";
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
}

@media screen and (orientation: landscape) {
  body.ios {
    width: 100vh;
    height: 100vw;
    transform-origin: 0 0;
  }

  body.ios.rotation-90 {
    transform: rotate(90deg) translateY(-100%);
  }

  body.ios.rotation90 {
    transform: rotate(-90deg) translateX(-100%);
  }
}
